<template>
  <v-main>
    <v-container>
      <v-card class="mx-auto pa-3" max-width="500" style="margin-top: 5vh">
        <v-card-title> Reset your password </v-card-title>
        <v-container>
          <account-recovery></account-recovery>
        </v-container>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import AccountRecovery from '@/components/AccountRecovery';
export default {
  metaInfo: {
    title: 'Account Recovery'
  },

  components: {
    AccountRecovery
  }
};
</script>

<style></style>
