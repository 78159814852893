<template>
  <v-form ref="form" v-model="valid" @submit.prevent="sendRecoveryEmail()">
    <v-alert type="success" icon="mdi-email-check-outline" v-if="success">{{ success }}</v-alert>
    <p v-else>
      Forgot your password? Enter the email address associated with your account and we'll send you
      a link to reset your password.
    </p>
    <v-alert type="error" v-if="error">{{ error }}</v-alert>
    <v-text-field
      v-model="email"
      type="email"
      label="Email"
      :rules="rules.emailRule"
      outlined
    ></v-text-field>
    <div class="text-center">
      <v-btn
        :loading="loading"
        block
        class="text-capitalize"
        color="primary"
        large
        @click="sendRecoveryEmail()"
        >{{ success ? 'Resend' : 'Send reset link' }}</v-btn
      >
      <v-btn color="primary" class="mt-2 text-capitalize" to="/login" large text>
        Return to Login
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import rules from '@/mixins/rules';
import firebase from 'firebase/app';
import config from '../config';
const api = config.api();
import 'firebase/auth';
export default {
  mixins: [rules],
  data: () => ({
    email: '',
    valid: true,
    success: null,
    error: null,
    loading: false
  }),
  created() {
    this.email = window.sessionStorage.getItem('email') || '';
  },
  methods: {
    async sendRecoveryEmail() {
      this.$refs.form.validate();
      this.success = '';
      if (this.valid) {
        this.loading = true;
        this.success = null;
        this.error = null;
        try {
          const subdomain = config.deployment === 'main' ? '' : config.deployment;
          const url = `${api.cloudFunction}/resetPassword?email=${this.email}&site=peeps&subdomain=${subdomain}`;
          await this.$axios.get(url);
          // this.success = res.data.message;
          this.success = 'Thanks, check your email for instructions to reset your password';
        } catch (err) {
          this.loading = false;
          if (err.response && err.response.data.message) {
            this.error = err.response.data.message;
          } else if (err.message) {
            this.err = err.message;
          } else {
            this.err = this.$store.state.ERROR_MESSAGES.default;
          }
          this.handleException(err, '\nError sending reset password email');
        }
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
